import * as React from "react"
import SEO from "../../components/seo";
import LayoutRechtsgebiete from "../../components/LayoutRechtsgebiete";


export default () => {

    return (<LayoutRechtsgebiete>
        <div id="content" className="animate-fade-in">
            <h1>Verkehrsrecht</h1>
            <p>
                <ul>
                    <li>Unfallregulierung</li>
                    <li>Fahrerlaubnisentzug</li>
                    <li>Bußgeldverfahren</li>
                    <li>Schmerzensgeld</li>
                </ul>
            </p>

            <p>
                Ich vertrete Fahrer und Halter von Fahrzeugen in Zusammenhang mit der Regelung von Verkehrsunfällen. Ob
                es um Sach- oder Personenschaden geht: Bei der Unfallregulierung werden oft und schnell
                Schadenspositionen gekürzt oder ganz abgelehnt. Ich setze Ihre Ansprüche auf Schadenersatz und
                Schmerzensgeld mit Nachdruck durch. Zu meiner Tätigkeit gehört insbesondere auch die Regelung des
                unfallbedingten Nutzungsausfalls, Schmerzensgeld, Verdienstausfall, Haushaltsführungsschaden, vermehrte
                Bedürfnisse, Vertretung in einem anschließenden straßenverkehrsrechtlichen
                Strafverfahren/Bußgeldverfahren.
            </p>
        </div>
    </LayoutRechtsgebiete>)
};

export const Head = () => {
    return (<SEO title={'Verkehrsrecht'}/>);
}
